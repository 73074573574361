@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700;800&display=swap');


*, html, ::after, ::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:  'Outfit';
}

body {
  font-family: 'Outfit', -apple-system;
  box-sizing: border-box;
}

.text-green {
  color: #149917!important;
}

.header {
  padding: 1em 0!important;
}

nav li {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.nav-link {
  color:#073308!important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.nav-link:hover {
  color: #7AFF7D!important;
}

.border-green {
  border-top: 1px solid #22FF26;
}

.hover:hover {
  color: #7AFF7D!important;
}

.top-margin {
  border-top: 1px solid transparent;
}

.shap-shap-header {
  font-size: 35px;
  font-weight: 700;
  color: #3423A6;
  line-height: 40px;
  padding: 1em 0 .5em;
}

.shap-shap-text {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: .5px;
  line-height: 30px;
  text-align: center;
  color: #3D3D3D;
}

.shap-shap-trade {
  border-radius: 30px;
  padding: 3em 3.5em;
  background: #FFF;
}

.trade-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-align: center;
  border-radius: 13px;
  padding: 1em;
}

.dollar-bag {
 margin-left: 5px;
  margin-top: -5px;
}

.bitcoin {
  background: #FFF6EF;
  border-radius: 13px;
  color: #E87A1D;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  padding: 1em;
}

.tether {
  background: #F7FFF0;
  border-radius: 13px;
  color: #149917;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  padding: 1em;
}

.naira {
  background: #F9F9F9;
  border-radius: 13px;
  color: #149917;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  padding: 1em;
}

.cedis {
  background: #FFFCEC;
  border-radius: 13px;
  color: #B86B35;
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  padding: 1em;
}

.shap-shap-btn {
  height: 56px;
  width: 200px;
  border-radius: 13px;
  border: none;
}

.trade-btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background: #22FF26;
}

.status-btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background: #FFFFFF;
}

#features {
  margin: 3em 0;
  padding: 6em 0;
  text-align: center;
}

#features img{
  width: 70px;
  height: 70px;
}

.feature-heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #3423A6;
  margin: 10px auto;
}

.feature-text {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin: 10px auto;
  color: #3D3D3D;
}

.how-it-works {
  background: #FFFFFF;
  padding: 6em 0;
}

.phone {
  z-index: 2;
}

.how-it-works-text {
  border-radius: 13px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #149917;
  background: #FAFAFA;
  padding: .5em 0;
  margin-top: 50px;
  margin-left: -100px;
  z-index: 1;
}

.workings {
  padding: 3em 0;
}

.workings img {
  height: 64px;
  width: 64px;
}

.workings-header {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  color: #000000;
}

.workings-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #646464;
}

.emails, .information, .minutes, .talk {
  margin-bottom: 3em;
}

.dist {
  margin-left: 1em;
  margin-top: 1em;
}

#FAQ {
  padding: 7em 0;
  background: #000000!important;
}

.faq-header {
  background: #101010;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  padding: .5em;
  border-radius: 13px;
  margin: 1em 0 2.5em;
}

.accordion-item {
  background: #000000!important;
  color: #FFFFFF;
  border-bottom: 1px solid #252525;
  margin: 1.5em 0;
  padding: 1.5em 0;
}

.show {
  color: #FFFFFF!important;
}

.accordion-item-header {
  font-family: 'Outfit', sans-serif;
  font-size: 90px!important;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-button {
  background: #000000!important;
  color: #FFFFFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.accordion-button:not(.collapsed) {
  color: #FFFFFF;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-body {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #BFBFBF;
}

.faq-button {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: .2em;
}

.faq-text {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 1, 2, 0.25);
}

#playstore {
  padding: 6em 0;
}

.playstore {
  z-index: 2;
}

.playstoreGreen {
  border: none;
  height: 56px;
  width: 168px;
  background: #22FF26;
  border-radius: 10px;
}

.playstoreWhite {
  border: none;
  height: 56px;
  width: 168px;
  background: #FFFFFF;
  border-radius: 10px;
}

.download-container {  
  background: #FFFFFF;
  margin-top: 30px;
  margin-top: 70px;
  border-radius: 13px;
  z-index: 1;
  margin-left: -300px;
  padding-left: 300px;
}

.download-header {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  color: #149917;
  padding: .5em 0;
}

.download-text {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  margin: 1em 0;
  color: #3D3D3D;
}

.download-text span {
  font-weight: 700;
}

.app-header {
  font-family: 'Bw Modelica';
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #3423A6;
}

.app-text {
  font-family: 'Bw Modelica';
  font-size: 8px;
  font-weight: 400;
  line-height: 6px;
  letter-spacing: 0em;
  text-align: left;
  color: #3423A6;
}

.app-image {
  height: 24px;
  width: 21px;
}

#footer {
  line-height: 50px;
  padding: 4em 0;
  text-align: center;
}

.footer a {
  font-size: 16px;
  color: #747474;
  text-decoration: none;
  font-weight: 500;
}

.footer ul{
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
}

.footer li a{
  text-decoration: none;
  margin: 0 8px;
  color: #747474;
  font-weight: 600;
}

.footer li a:hover {
  color: #149917;
}

.whatsapp {
  color: #149917!important;
  font-weight: 700!important;
}

.whatsapp:hover {
  color: #747474!important;
}

.whatsapp::after {
  content: '';
  border-right: 1px solid #747474;
  padding: 0 6px;
}

.email-footer {
  font-weight: 700!important;
}

.email-footer::before {
  content: '';
  padding: 0 6px;
}

.email {
  color: #141414!important;
  width: 100%;
}

.footer-logo {
  height: 50px;
  width: 130px;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('./assets/images/minus.svg');
  transform: rotate(180deg);
  background-size: 32px 32px!important;
  width: 32px;
  height: 32px;
}

.accordion-button::after {
  flex-shrink: 0;
  margin-left: auto;
  background-image: url('./assets/images/plus.svg');
  background-repeat: no-repeat;
  background-size: 32px 32px!important;
  width: 32px;
  height: 32px;
  transition: transform .2s ease-in-out;
}

/* Trade page */
.trade-bull-container {
  margin: 5em 0 5em;
}

.trade-bull-header {
  margin: 2px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.trade-bull-text {
  margin: 2px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #1F1564;
}

.register {
  margin: 6em 0;
}

.shap-shap-btn-black {
  background: #000000;
  color: #FFFFFF;
  height: 52px;
  width: 100%;
  border-radius: 13px;
  border: none;
}

.shap-shap-btn-trade {
  height: 52px;
  width: 280px;
  border-radius: 13px;
  border: none;
  background: #000000;
  color: #FFFFFF;
}

#Trade input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #22FF26;
}


#Trade input:focus {
  border-color: #22FF26;
}

#Trade ::placeholder {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #073308;
}

#Trade button {
  margin-top: 1em;
}


.navbar-light .navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

#trading {
  margin: 5em 0;
}

.select {
  height: 52px;
  width: 100%;
  border-radius: 13px;
  background: #D3FFD4;
  padding: 5px 2em;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../src/assets/images/carret.svg");
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
}

.amount {
  margin: 5px 0;
}

.amount-form {
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 13px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  padding: 15px 0;
  background: #F7F7F7;
  color: #1F1564;
}

.amount-form::placeholder {
  color: #1F1564;
}

.amount-form:active {
  border: none;
}

.amount-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #149917;
}

.payments {
  margin: 30px 0 5px;
  text-align: center;
}

.payments-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.text-blue {
  color: #1F1564;
}

.payment-options {
  background: #EDFFED;
  padding: 1.3em 0;
  border-radius: 13px;
}

.payment-options img {
  cursor: pointer;
}

.bitcoin-form input::placeholder {
  color: #1F1564!important;
}


.coin-type {
  height: 34px;
  width: 144px;
  border-radius: 13px;
  font-size: 14px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  background: #000000;
  color: #FFFFFF;
  margin: 0 auto;
}

.btc-address-form {
  width: 100%;
  border: none;
  border-radius: 13px;
  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  padding: 15px 2em;
  background: #F7F7F7;
  color: #1F1564!important;
  margin: 15px 0;
}

.btc-address-form::placeholder {
  color: #1F1564!important;
}

.submit-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #1F1564!important;
}


.validate {
  color: #FF3333;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.total-amount {
  margin: 2em 0;
}

.total-amount p:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #1F1564;
}

.total-amount p:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;  
}

.upload-image {
  width: 100%;
  border-radius: 13px;
  padding: 1em  0 1em;
}

.lottie {
  height: 100px;
  width: 100px;
}

.upload-image-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #1F1564;
  margin: 1em 0;
  border: none;
  background: none;
}

.e-code {
  margin: 4em 0;
}

.e-code-text {
  text-align: center;
  color: #149917;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.e-code-form {
  width: 100%;
  font-size: 18px;;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  border-radius: 13px;
  letter-spacing: 0em;
  padding: 15px 2em;
  background: #F7F7F7;
  color: #1F1564!important;
  margin: 15px 0; 
}

.e-code-form::placeholder {
  color: #1F1564!important;
}

.submit-btn {
  border: none;
  background: none;
}

.confirm-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1em 0;
  color: #000000;
}

.confirm-details {
  margin: 1.2em 0;
}

.card-category {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #1F1564;
}

.value {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #1F1564;  
}

.final-value {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;  
}

.trade-conditions-link {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FF1654;
  text-align: center;
  text-decoration: none;
}

.trade-conditions-link div {
  margin: 2em 0 1em;
  padding: 2em 0;
}

.terms-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FF1654;
  margin: 2em 0;
}

#trade-terms {
  padding-top: 3em;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.94);
}

.close-trade-terms {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}


.trade-terms-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #1F1564;
  margin-bottom: 1em;
}

#success {
  padding-top: 2em;
  height: 100vh;
}

.that-was-sharp {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.success-feedback-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #1F1564;
  margin: .7em 0;
}

.alternative-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 8em;
}

.go-to-status {
  margin: 1em 0;
}

#confirmation {
  position: relative;
}

#trade-status {
  height: 100vh;
  position: relative;
}

.trade-status-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  padding: 3em 0 2em;
}

.trade-status-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.trade-status-form {
  padding-top: 5em;
}

#trade-status input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #22FF26;
  margin-bottom: 2em;
}

#trade-status input:focus {
  border-color: #22FF26;
}

#trade-status ::placeholder {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#trade-status-paid, #trade-status-pending, #trade-status-rejected {
  padding: 1em 0 3em;
}

.modal-paid, .modal-pending, .modal-rejected  {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.rejected-reason {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

#trade-status-rejected a {
  text-decoration: none;
}

#rejection {
  padding-top: 3em;
}

.rejection-reason {
  text-decoration: none;
}

.rejection-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  padding: 1em 0 3em;
}

.rejection-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #1F1564;
}

.rejection-images {
  padding: 4em 0;
}

.main {
  position: relative;
}

.steam {
  position: absolute;
  display: none;
}

.playstation {
  position: absolute;
  display: none;
}

.amazon {
  position: absolute;
  display: none;
}

.apple {
  position: absolute;
  display: none;
}

.xbox {
  position: absolute;
  display: none;
}

.bestbuy {
  position: absolute;
  display: none;
}

.ebay {
  position: absolute;
  display: none;
}

@media screen and (min-width: 820px) {
  .main {
    position: relative;
  }

  .steam {
    position: absolute;
    top: 10px;
    left: 15%;
    display: block;
  }

  .playstation {
    position: absolute;
    left: 89%;
    top: -1.3em;
    display: block;
  }

  .amazon {
    position: absolute;
    left: 2%;
    top: 3.1em;
    display: block;
  }

  .apple {
    position: absolute;
    left: 90%;
    top: 50%;
    display: block;
  }

  .xbox {
    position: absolute;
    left: 0%;
    bottom: 3em;
    display: block;
  }

  .bestbuy {
    position: absolute;
    left: 85%;
    top: 0;
    display: block;
  }

  .ebay {
    position: absolute;
    left: 18%;
    bottom: 1.2em;
    display: block;
  }

}

@media screen and (max-width: 1000px) {
  .navbar-brand-mobile {
    margin: 0 auto!important;
  }

  .shap-shap-header {
    font-size: 32px;
    font-weight: 800;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }

  .shap-shap-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }

  .workings {
    text-align: center;
  }

  .how-it-works-text {
    margin-left: auto;
  }

  .download-text {
    font-size: 18px;
    text-align: center;
    margin: 1em 0;
    padding: 0 1em;
  }

  .tooltip-content {
    left: 0px!important;
  }

  .tooltip-content-address {
    left: 0px;
    right: 0px!important;
  }

}

@media screen and (max-width: 800px) {
  .download-header {
    font-size: 25px;
  }
}

@media  screen and (max-width: 500px) {
  .coin-text {
    display: none!important;
  }

  .download-header {
    font-size: 25px;
  }

  .shap-shap-trade {
    border-radius: 30px;
    padding: 2em 2em;
    background: #FFF;
  }

  .shap-shap-arrow {
    margin-top: -1.5rem!important;
  }

  .shap-shap-header {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }

  .shap-shap-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
  }

  #features {
    margin: 3em 0;
    padding: 2em 0;
    text-align: center;
  }

  .how-it-works {
    background: #FFFFFF;
    padding: 4em 0;
  }

  .workings-header {
    margin-top: 1em;
  }
  
  .whatsapp::after {
    padding: 0 6px;
  }

  .download-text {
    font-size: 18px;
  }

  .feature-heading {
    font-size: 25px;
  }

  .accordion-button {
    font-size: 16px;
  }

  .faq-header {
    font-size: 25px;
  }

  #FAQ {
    padding: 4em 0;
  }

  .footer a {
    font-weight: 500;
  }

}

@media screen and (max-width: 991px) {
  .navbar-nav {
    margin-left: 2em!important;
  }
  .download-container {  
    background: #FFFFFF;
    margin-top: 30px;
    margin-top: 70px;
    border-radius: 13px;
    z-index: 1;
    margin-left: 0px;
    padding-left: 0px;
  }
  
  .download-header {
    font-weight: 700;
    text-align: center;
    color: #149917;
    padding: .5em 3em;
  }

  .border-green {
    border-top: none;
  }

  .dist {
    margin: 0 auto;
    padding: 0 auto;
  }

  .workings-header {
    margin: .8em 0;
  }
}

.continue-btn {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.main-overlay-btn {
  position: fixed;
  bottom: 15%;
  right: 10%;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.main-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tooltip-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tooltip-content {
  font-size: 14px;
  position: relative;
  font-weight: 600;
  margin-bottom: 19px;
  left: -200px;
}

.tooltip-content.trading.payment-overlay {
  top: -100%;
  left: 0;
  right: 40%;
}
.tooltip-content.up {
  margin-top: 19px;
  margin-bottom: 0;
}
.tooltip-content.trading.image-upload {
  left: 0;
  right: 0;
}

.tooltip-content-address {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 19px;
  left: 300px;
  right: 300px;
}

.trading-image {
  width: 62px;
  height: 62px;
  border-radius: 13px;
}

.tooltip-content p {
  padding: 30px;
  border-radius: 13px;
  margin-bottom: 0;
  background-color: #fff;
}

.caret {
  width: 0;
  height: 0;
  display: inline-block;
  border: 10px solid transparent;
}
.caret.down{
  border-top-color: #fff;
  right: 35px;
  position: absolute;
}
.caret.up{
  border-bottom-color: #fff;
  right: 35px;
  position: absolute;
  top: -19px;
}

.status-coin {
  height: 45px;
  width: 45px;
}

.bank-details {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3423A6!important;
}

.paid-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #149917;  
}


.pending-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #073308;
}

.rejected-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #FF1654; 
}

.see-why {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #FF1654; 
}

.tooltip-button {
  z-index: 100;
  position: fixed;
  bottom: 8%;
  right: 15%;
}

.spinner {
  display: flex;
  justify-content: center;
}

.not-found {
  height: 90vh;
}

.error-lottie {
  height: 500px;
  width: 500px;
}

.error-lottie-mobile {
  height: 250px;
  width: 250px;
}

